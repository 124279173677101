@font-face {
    font-family: PlusJakartaSans;
    src: url("fonts/PlusJakartaSans-Regular.ttf");
}

@font-face {
    font-family: PlusJakartaSansBold;
    src: url("fonts/PlusJakartaSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: PlusJakartaSansExtraBold;
    src: url("fonts/PlusJakartaSans-ExtraBold.ttf");
    font-weight: bolder;
}

body {
    font-family: PlusJakartaSans;
    max-width: 1400px;
    margin: 0 auto;
}

.main-color {
    color: #2EB454;
}

.white {
    color: #fff;
}

.black {
    color: #000;
}

.se-button {
    border-radius: 25px;
    color: #fff;
    padding-left: 2em;
    padding-right: 2em;
}

.header-contact-btn {
    background-color: #000;   
    border-color: #000;
}

.no-marker {
    list-style: none;
}

.col-img {
    width: 100%;
}

.content-header {
    font-family: PlusJakartaSansExtraBold;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
}

.right {
    float: right;
}

.left {
    float: left;
}

.middle {
    text-align: center;
}

.content-body {
    text-align: left;
    margin-bottom: 0.25rem;
    line-height: 2rem;
}

.content-body > ul {
    padding-left: 0;
}

.header-img {
    width: 100%;
    position: absolute;
    z-index: 100;
    max-width: 1400px;
}

.bg1 {
    /* background-image: url('../img/bg1.png');
    background-repeat: no-repeat;
    background-size: 100%; */
    min-height: 32rem;
    padding: 0;
}

.part1 {
    min-height: 32rem;
    position: absolute;
    top: 3rem;
    margin: 3rem;
    z-index: 999;
}

.bg2 {
    background-image: url('../img/bg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 22rem;
}

.bg3 {
    background-image: url('../img/bg3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 40rem;
}

.title {
    font-size: 3em;
}

.header {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
}

.logo {
    display: inline-block;
    margin-top: 0.5rem;
}

.logo > img {
    height: 2.7rem;
}

.header-left {
    display: inline-block;
}

.header-right {
    display: inline-block;
    margin-left: 5rem;
}

.nav-link {
    color: #fff;
}

.nav-link:hover {
    color: #fff;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #2EB454;
    text-underline-offset: 5px;
}

.nav-link:active, .nav-link.active {
    color: #fff;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #2EB454;
    text-underline-offset: 5px;
}

.bg-main {
    background-color: #2EB454;
}

.no-padding {
    padding: 0;
}

.talent-img {
    width: 100%;
}

.d-md-none.talent-img {
    width: 100vw;
}

.brand-logo {
    width: 15%;
    margin: 1rem;
}

.d-md-none > .brand-logo {
    width: 18% !important;
}

.elite-description {
    font-size: 1.5em;
    text-align: center;
}

.card-img-title {
    font-family: PlusJakartaSansBold;
}

.card {
    text-align: left;
    margin: 0.5em;
    padding: 0 0.5em 0.5em 0.5em;
    background-color: transparent;
    border: none;
}

/* .owl-item.active.center > div.card {
    background-color: #2EB454 !important;
    border-radius: 25px;
    color: #fff;
} */
.center > .card-wrapper > .card {
    background-color: #2EB454 !important;
    border-radius: 25px;
    color: #fff;
    padding: 2.5rem 1rem 1rem 1rem;
    margin-top: -2rem;
}

.center > .card-wrapper > .card > a {
    color: #fff;
}

.card-img {
    width: 80%;
    margin: auto;
    position: relative;
    z-index: 999;
}

.display-3 {
    font-family: PlusJakartaSansExtraBold;
}

.footer-bg {
    background-image: url('../img/footer-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 45rem;
}

.se-hr {
    border: 3px solid #2EB454;
    /* border-radius: 5px; */
    height: 0px !important;
    opacity: 1;
}

.img-mac {
    width: 100%;
    margin-top: -2rem;
}

.social-logo {
    width: 3.5rem;
    margin: 1rem;
}

.footer-title {
    font-family: PlusJakartaSansBold;
    font-size: 1.5em;
}

.copyrights {
    margin-top: 7rem;
}

.team-bg {
    min-height: 35rem;
}

.user {
    /* margin-bottom: 10rem; */
}

.user > img {
    /* width: 60%; */
}

.user-left {
    margin-top: 10rem;
}

.user-info {
    margin-top: -9rem;
    margin-left: 2rem;
}

.contact-form-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    box-shadow: none;
    border-radius: 0;
    padding-left: 0;
    color: #fff;
}

.contact-form-input:focus {
    color: #fff;
    background-color: transparent;
    box-shadow: none;
}

.contact-form-half {
    float: left;
    width: 48%;
}

.team-footer-bg {
    background-image: url('../img/footer-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 38rem;
}

.name_shape {
    position: absolute;
    height: 4rem;
    width: 4rem;
}

.name {
    font-family: PlusJakartaSansBold;
    padding-left: 1rem;
    text-wrap: nowrap;
}

.user-right-info {
    margin-top: 10rem;
}

.user-title {
    padding-left: 1rem;
}

.user-right {
    margin-left: 4rem;
}

.part-team {
    width: 60%;
    position: absolute;
    top: 3rem;
    margin: 3rem;
    z-index: 999;
}

.ourteam {
    /* margin-left: -4rem; */
}

.part1-campaign {
    min-height: 30rem;
    position: absolute;
    top: 3rem;
    margin: 3rem;
    z-index: 999;
    width: 80%;
}

.campaign-left-part {
    position: absolute;
    height: 37rem;
}

.campaign-bg {
    height: 30rem;
    padding: 0;
}

.campaign-img {
    height: 30rem;
    float: right;
}

.nav-btn {
    width: 100%;
}

.dots {
    margin: auto;
    text-align: center;
}

.dots button {
    border: none !important;
    background-color: transparent !important;
}

.dots .owl-dot span {
    display: inline-block !important;
    height: 10px !important;
    width: 10px !important;
    border: 1px solid #7A7A7A !important;
    color: #7A7A7A !important;
    background-color: #7A7A7A !important;
    border-radius: 10px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.dots .owl-dot.active span {
    border: 1px solid #2EB454 !important;
    color: #2EB454 !important;
    background-color: #2EB454 !important;
}

.grey-bg {
    background-color: #F0F0F0;
}

.avatar {
    width: 100%;
}

.user-row {
    margin-top: -3rem;
}

.card-img > img {
    border: 1px solid white;
    border-radius: 5px;
}

.sm-img {
    width: 70%;
}

.img-wrapper {
    text-align: center;
}

.no-margin {
    margin: 0;
}

.d-md-none .bg1 {
    min-height: 35rem;
    background-image: url('../img/Mobile_BG.png');
    background-size: cover;
}

.d-md-none .part1 {
    /* min-height: 35rem; */
    top: 0.5rem;
    position: relative;
    z-index: auto;
}

.d-md-none > .header-left > .logo > img {
    height: 1.5rem;
}

.d-md-none > .header-left {
    display: block;
    /* text-align: center; */
}

.d-md-none .se-button {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.8rem;
}

.d-md-none .navbar-toggler {
    font-size: 0.8rem;
    box-shadow: none;
    border: none;
    background-color: transparent !important;
    position: absolute;
    top: 0;
    right: 0.5rem;
}

.d-md-none .header-right {
    font-size: 0.6rem;
    float: right;
    margin-top: -2rem;
}

.d-md-none .navbar-expand {
    padding-top: 0;
}

.d-md-none .navbar-expand .navbar-nav .nav-link {
    padding-left: 5px;
    padding-right: 5px;
}

.d-md-none .header-contact-btn {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
    background-color: #2EB454;
}

.d-md-none .img-mac {
    margin-top: 0;
}

.d-md-none .copyrights {
    margin-top: 1rem;
}

.bg-black {
    background-color: #000;
}

.bg-white {
    background-color: #fff !important;
}

.mobile-campaigns {
    background-size: 100%;
    min-height: auto;
}

.d-md-none .elite-description{
    font-size: 1em;
}

.d-md-none > .part-team {
    margin-top: 0.5rem;
}

.d-md-none .avatar {
    width: 50%;
}

.d-md-none .user-title {
    font-size: 0.5rem;
}

.d-md-none .user-right-info {
    margin-top: 3rem;
    margin-right: 4rem;
}

.d-md-none .user-left-info {
    margin-top: 2.5rem;
    margin-left: 4rem;
}

.d-md-none .user-right-info .user-name, .d-md-none .user-left-info .user-name {
    font-size: 1rem;
}

.d-md-none .campaign-left-part {
    height: 10rem;
}

.d-md-none .campaign-img {
    height: 8rem;
}

.d-md-none .part1-campaign {
    top: 1rem;
    min-height: auto;
    font-size: 1rem;
}
.d-md-none .campaign-bg {
    height: auto;
}

/*
Special for Galaxy Fold small screen
*/

@media only screen and (min-width: 0px) and (max-width: 280px) {
    .d-md-none > .header-left > .logo > img {
        /* display: none; */
        height: 0.4rem;
    }

    .d-md-none .header-right {
        margin-left: 0;
        font-size: 0.3rem;
    }

    .d-md-none > .part1 {
        font-size: 0.5rem;
        min-height: fit-content;
    }

    .d-md-none .se-button {
        padding-left: 5px;
        padding-right: 5px;
    }

    .d-md-none .user-left-info {
        margin-top: 1.5rem;
        margin-left: 1rem;
    }

    .d-md-none .user-right-info {
        margin-top: 1rem;
        margin-right: 1rem;
    }
}

/**
* for 300px screen
*/
@media only screen and (min-width: 281px) and (max-width: 360px) {
    .d-md-none .user-right-info {
        margin-top: 2rem;
        margin-right: 2rem;
    }
}

/**
* iPad mini
*/
@media only screen and (min-width: 361px) and (max-width: 912px) {
    .team-bg {
        min-height: 19rem;
    }

    .user-right-info {
        margin-top: 4rem;
    }

    .user-row {
        margin-top: 1rem;
    }

    .d-md-none .user-right-info {
        margin-top: 2rem;
        margin-right: 2rem;
    }
}

.elite-title {
    font-family: PlusJakartaSansBold;
    font-size: 3rem;
}

.col-6 > .content-header {
    font-family: PlusJakartaSansExtraBold;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
}

.col-6 > .content-body {
    font-size: 1rem;
    line-height: 1.3rem;
}

.main-title {
    font-family: PlusJakartaSansBold;
}

.d-md-none .header-img {
    position: relative;
    height: 35rem;
}

.d-md-none .navbar-nav {
    width: 100vw;
    padding-left: 1rem;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.d-md-none .bgComem {
    min-height: 35rem;
    background-image: url('../img/projects/comem_banner.png');
    background-size: cover;
}

.no-margin-left-right {
    margin-left: 0;
    margin-right: 0;
}

.d-md-none .bgPS {
    min-height: 35rem;
    background-image: url('../img/projects/ps_banner.png');
    background-size: cover;
}

.d-md-none .bgCloseup {
    min-height: 35rem;
    background-image: url('../img/projects/closeup_banner.png');
    background-size: cover;
}

.d-md-none .bgComfort {
    min-height: 35rem;
    background-image: url('../img/projects/comfort_banner.png');
    background-size: cover;
}

.d-md-none .bgNuvi {
    min-height: 35rem;
    background-image: url('../img/projects/nuvi_banner.png');
    background-size: cover;
}

.d-md-none .bgTeam {
    min-height: 35rem;
    background-image: url('../img/projects/our_team_banner.png');
    background-size: cover;
}

.red {
    color: red;
}